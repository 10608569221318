<template>
  <v-dialog width="400" v-model="dialog">
    <template v-slot:activator="{ on }">
      <v-btn icon v-on="on" @click="fetch">
        <v-icon size="36">mdi-plus-circle</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>Añadir servicio</v-card-title>
      <v-card-text>
        <v-row justify="center">
          <v-col cols="12" md="8" class="py-0">
            <label>Tipo</label>
            <v-select
              outlined
              dense
              hide-details
              :items="services"
              v-model="form.artist_type_id"
              item-text="name"
              item-value="id"
            ></v-select>
          </v-col>
          <v-col cols="12" md="8" class="py-0" v-if="form.artist_type_id == -1">
            <label>Nombre</label>
            <v-text-field
              outlined
              dense
              hide-details
              v-model="form.name"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          outlined
          @click="
            dialog = false;
            files = [];
          "
          style="height: 25px; width: 100px"
          elevation="0"
          >{{ $t("cancel") }}</v-btn
        >
        <v-btn
          @click="save"
          style="
            width: 100px !important;
            color: #363533;
            height: 25px;
            padding-top: 10px !important;
          "
          elevation="0"
        >
          <v-icon style="margin-right: 5px" size="14px">$save</v-icon>
          {{ $t("save", { name: $t("") }) }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "AddService",
  props: {
    already_services: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      dialog: false,
      form: { artist_type_id: null },
      services: [],
    };
  },
  methods: {
    ...mapActions("tattooers", ["getTypeArtist"]),
    fetch() {
      this.getTypeArtist({}).then((response) => {
        let ss = response.data;

        this.services = ss.filter((s) => {
          let f = this.already_services.find((as) => as.artist_type_id == s.id);

          console.log("TEST");

          console.log(f);

          if (f) return false;
          return true;
        });

        this.services.push({
          id: -1,
          name: "Otro",
        });
      });
    },
    ...mapActions("services", ["addService"]),
    save() {
      if (this.form.artist_type_id !== -1) {
        this.form.name = this.services.find(
          (s) => s.id == this.form.artist_type_id
        ).name;
      } else {
        this.form.artist_type_id = null;
      }
      this.addService({ service: this.form }).then(() => {
        this.$alert(this.$t("save_ok"));
        this.$emit("update");
        this.form = { artist_type_id: null };
        this.dialog = false;
      });
    },
  },
};
</script>
